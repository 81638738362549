import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB4j8NfUfPMIeTtVK6xMO6zzT96S9eyTNk",
  authDomain: "mcmsw-4248c.firebaseapp.com",
  projectId: "mcmsw-4248c",
  storageBucket: "mcmsw-4248c.appspot.com",
  messagingSenderId: "102090558228",
  appId: "1:102090558228:web:bad09cea420209c2bb5001"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, app, db };
