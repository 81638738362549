// Home.js
import NavigationBar from "../components/nav/NavigationBar";
import AuthRedirect from "../auth/AuthRedirect";
import Card from "../components/cards/Card";
import withAuthRedirect from "../auth/AuthRedirect";

const Home = () => {
  return (
    <div>
      <AuthRedirect />
      <NavigationBar pageTitle={"MCMSW"} />

      <div className="m-2 mt-3 flex flex-col md:flex-row">
        <section className="flex flex-col gap-4 md:basis-1/3">
          <Card />
          <Card />
          <Card />
        </section>
        <section className="flex flex-col gap-6  md:basis-2/3"></section>
      </div>

    </div>
  );
};

export default withAuthRedirect(Home);
