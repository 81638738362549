import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  async function logout() {
    await auth.signOut();
    navigate("/login");
  }

  return (
    <div ref={ref}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-button text-black-500 mr-1 rounded-lg bg-pale-blue p-2 transition-all duration-200 ">
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-slot="icon"
            className="h-6 w-6 stroke-white md:h-5 md:w-5">
            <path
              fill-rule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clip-rule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="h-6 w-6 md:h-5 md:w-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M5 7H19"
                stroke="#ffffff"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
              <path
                d="M5 12L19 12"
                stroke="#ffffff"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
              <path
                d="M5 17L19 17"
                stroke="#ffffff"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
            </g>
          </svg>
        )}
      </button>
      {isOpen && (
        <ul className="animate-fade animate-ease-in-out animate-duration-300 absolute bottom-0 right-0 top-14 w-full bg-white p-1 text-medium-blue ring-slate-200 md:right-2 md:top-12 md:mt-4 md:h-fit md:w-1/6 md:rounded-md md:shadow-md md:ring-2">
          <div className="flex flex-col md:gap-0">
            <section>
              <li
                onClick={() => {
                  navigate("/");
                  setIsOpen(false);
                }}
                className="dropdownItem flex transition-colors cursor-pointer flex-row  items-center justify-between rounded-md px-3 py-3 text-xl font-semibold hover:bg-medium-blue hover:text-white md:hidden md:px-2 md:py-2 md:text-xs">
                Home
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  dataSlot="icon"
                  className="h-6 w-6 md:h-4 md:w-4">
                  <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                  <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                </svg>
              </li>
              <li
                onClick={() => {
                  navigate("/income");
                  setIsOpen(false);
                }}
                className="dropdownItem flex transition-colors cursor-pointer flex-row  items-center justify-between rounded-md px-3 py-3 text-xl font-semibold  hover:bg-medium-blue hover:text-white md:hidden md:px-2 md:py-2 md:text-xs">
                Income
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  dataSlot="icon"
                  className="h-6 w-6 md:h-4 md:w-4">
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM9.763 9.51a2.25 2.25 0 0 1 3.828-1.351.75.75 0 0 0 1.06-1.06 3.75 3.75 0 0 0-6.38 2.252c-.033.307 0 .595.032.822l.154 1.077H8.25a.75.75 0 0 0 0 1.5h.421l.138.964a3.75 3.75 0 0 1-.358 2.208l-.122.242a.75.75 0 0 0 .908 1.047l1.539-.512a1.5 1.5 0 0 1 .948 0l.655.218a3 3 0 0 0 2.29-.163l.666-.333a.75.75 0 1 0-.67-1.342l-.667.333a1.5 1.5 0 0 1-1.145.082l-.654-.218a3 3 0 0 0-1.898 0l-.06.02a5.25 5.25 0 0 0 .053-1.794l-.108-.752H12a.75.75 0 0 0 0-1.5H9.972l-.184-1.29a1.863 1.863 0 0 1-.025-.45Z"
                    clipRule="evenodd"
                  />
                </svg>
              </li>
              <li
                onClick={() => {
                  navigate("/customers");
                  setIsOpen(false);
                }}
                className="dropdownItem transition-colors flex cursor-pointer flex-row  items-center justify-between rounded-md px-3 py-3 text-xl font-semibold  hover:bg-medium-blue hover:text-white md:hidden md:px-2 md:py-2 md:text-xs">
                Customers
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  data-slot="icon"
                  className="h-6 w-6 md:h-4 md:w-4">
                  <path
                    fill-rule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </li>
              <hr className="mx-3 my-2 md:hidden" />
            </section>
            <section>
              <li
                onClick={() => logout()}
                className="dropdownItem flex transition-colors cursor-pointer flex-row items-center justify-between rounded-md px-3 py-3 text-xl font-semibold  hover:bg-red-600 hover:text-white md:px-2 md:py-2 md:text-xs">
                Logout
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  dataSlot="icon"
                  className="h-7 w-7 -rotate-90 md:h-5 md:w-5">
                  <path d="M11.47 1.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 0 1-1.06-1.06l3-3ZM11.25 7.5V15a.75.75 0 0 0 1.5 0V7.5h3.75a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h3.75Z" />
                </svg>
              </li>
            </section>
          </div>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
