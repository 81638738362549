// AuthRedirect.js
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { Link } from "react-router-dom";

export default function withAuthRedirect(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigate("/login");
        }
      });

      return () => unsubscribe();
    }, [navigate]);

    if (isAuthenticated === false) {
      return (
        <div>
          <p>You are not logged in.</p>
          <Link to="/login">Go to Login</Link>
        </div>
      );
    }

    return isAuthenticated === true ? <Component {...props} /> : null;
  };
}
