// PasswordReset.js
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setMessage] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        navigate("/login");
      })
      .catch(() => {
        setMessage("Error resetting password. Please try again.");
      });
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-10  bg-medium-blue">
      <h1 className="text-2xl font-semibold text-white">Password Reset</h1>
      <form onSubmit={handlePasswordReset} className="flex flex-col gap-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-200">
            Email
          </label>
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-2 block w-full rounded-md  bg-dark-blue p-3 text-gray-200 ring-0 hover:ring-1 active:ring-1 ring-blue-400 transition-all  focus:outline-none focus:ring-1 "
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="from-cyan to-light-blue hover:from-light-blue hover:to-cyan mt-4 block w-full rounded-md bg-gradient-to-r p-3 text-sm font-medium text-white shadow-md transition-colors focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
            Send password reset email
          </button>
        </div>
        <div>
        <div className="text-center text-sm text-red-500">{errorMessage}</div>
        </div>
        <div className="flex justify-center gap-4 text-center text-sm text-gray-200">
          <a href="/login" className="hover:text-cyan text-white">
            Return to Login
          </a>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
