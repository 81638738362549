// Login.js

import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", "#16213C");

    return () => {
      metaThemeColor.setAttribute("content", "#1d85e6"); // reset to default color
    };
  }, []);

  // handle login form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        navigate("/");
      })
      .catch(() => {
        setErrorMessage("Error signing in. Please try again.");
      });
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-10  bg-medium-blue">
      <h1 className="text-2xl font-semibold text-white">Login</h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-200">
            Email
          </label>
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-2 block w-full rounded-md  bg-dark-blue p-3 text-gray-200 ring-0 hover:ring-1 active:ring-1 ring-blue-400 transition-all  focus:outline-none focus:ring-1 "
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-200">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-2 block w-full rounded-md  bg-dark-blue p-3 text-gray-200 ring-0 hover:ring-1 active:ring-1 ring-blue-400 transition-all  focus:outline-none focus:ring-1 "
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="mt-4 block w-full rounded-md bg-gradient-to-r from-cyan to-light-blue p-3 text-sm font-medium text-white shadow-md transition-colors hover:from-light-blue hover:to-cyan focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
            Login
          </button>
        </div>
        <div className="flex justify-center gap-4 text-center text-sm text-gray-200">
          <a href="/password-reset" className="text-white transition-colors hover:text-cyan">
            Reset Password
          </a>
        </div>
        <div className="text-center text-sm text-red-500">{errorMessage}</div>
      </form>
    </div>
  );
};

export default Login;
