// Card.js

const Card = () => {
  return (
    <div
      className=
        "flex flex-row items-center justify-between rounded-xl bg-white p-2 text-black ring-0 ring-slate-300 shadow-sm transition-all duration-75 hover:ring-1 "
      >
      <div className="flex flex-col">
        <h2 className="pl-2 text-sm font-medium">Hello</h2>
        <p className="pl-2 text-xs">There</p>
      </div>
    </div>
  );
};

export default Card;
